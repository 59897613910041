<template>
  <el-card id="deliver-controller" class="box-card" style="width: 94%; height: 100vh; margin-left: 3%;margin-bottom: 20px;">
    <div class="entire">
      <div class="pusher">
        <!-- <span>提示:点击相应的肥料罐进行参数设置和启动操作</span> -->
        <span style="margin-left: 1%; font-size: 14px; font-weight: bold"
          >当前设备：<span
            style="color: green; font-size: 14px; font-weight: 400"
            >{{ queryParams.deviceName }}</span
          >&nbsp;
          <el-tag
            size="mini"
            v-if="using == false"
            type="success"
            style="font-weight: 400"
            >空闲中</el-tag
          >
          <el-tag size="mini" v-else style="font-weight: 400">工作中</el-tag>
        </span>
      </div>
      <div class="tfj-content" style="top: 10px; left: -30px">
        <img
          src="@/assets/tfj/tfj_close.png"
          style="width: 100%"
          alt="加载失败"
        />
        <div class="Nbox" @click="startUpn"></div>
        <div class="Pbox" @click="startUpp"></div>
        <div class="Kbox" @click="startUpk"></div>
        <div class="Wbox" @click="startUp"></div>
        <!-- 出口箭头 -->
        <div>
          <img
            ref="arrow"
            style="
              position: absolute;
              top: 75.5%;
              left: 84.5%;
              width: 14%;
              display: none;
            "
            src="@/assets/tfj/arrow.png"
            alt=""
          />
        </div>
        <!--上部管道图遮罩层-->
        <div id="pipeTop-shade" ref="pipeTopShade">
          <div id="pipeTop" ref="pipeTop"><!-- 上部管道图 --></div>
        </div>
        <!--下部管道图遮罩层-->
        <div id="pipeBottom-shade" ref="pipeBottomShade">
          <div id="pipeBottom" ref="pipeBottom"><!-- 下部管道图 --></div>
        </div>
        <!-- 上纵管道1 -->
        <div id="pipeUp1-shade">
          <div id="pipeUp1" ref="pipeUp1"></div>
        </div>
        <!-- 下纵管道1 -->
        <div id="pipeDown1-shade">
          <div id="pipeDown1" ref="pipeDown1"></div>
        </div>
        <!-- 上纵管道2 -->
        <div id="pipeUp2-shade">
          <div id="pipeUp2" ref="pipeUp2"></div>
        </div>
        <!-- 下纵管道2 -->
        <div id="pipeDown2-shade">
          <div id="pipeDown2" ref="pipeDown2"></div>
        </div>
        <!-- 上纵管道3 -->
        <div id="pipeUp3-shade">
          <div id="pipeUp3" ref="pipeUp3"></div>
        </div>
        <!-- 下纵管道3 -->
        <div id="pipeDown3-shade">
          <div id="pipeDown3" ref="pipeDown3"></div>
        </div>
        <!-- 上纵管道4 -->
        <div id="pipeUp4-shade">
          <div id="pipeUp4" ref="pipeUp4"></div>
        </div>
        <!-- 下纵管道4 -->
        <div id="pipeDown4-shade">
          <div id="pipeDown4" ref="pipeDown4"></div>
        </div>
        <!-- 上纵管道5 -->
        <div id="pipeUp5-shade">
          <div id="pipeUp5" ref="pipeUp5"></div>
        </div>
        <!-- 阀门-->
        <div>
          <!-- 上1 -->
          <img
            ref="vu1"
            style="
              position: absolute;
              width: 3%;
              top: 18%;
              left: 11%;
              display: none;
            "
            src="@/assets/tfj/valveleft.png"
            alt=""
          />
          <!-- 上2 -->
          <img
            ref="vu2"
            style="
              position: absolute;
              width: 3%;
              top: 18%;
              left: 28.6%;
              display: none;
            "
            src="@/assets/tfj/valveleft.png"
            alt=""
          />
          <!-- 上3 -->
          <img
            ref="vu3"
            style="
              position: absolute;
              width: 3%;
              top: 18%;
              left: 45.6%;
              display: none;
            "
            src="@/assets/tfj/valveleft.png"
            alt=""
          />
          <!-- 下1 -->
          <img
            ref="vd1"
            style="
              position: absolute;
              width: 3%;
              top: 70%;
              left: 11%;
              display: none;
            "
            src="@/assets/tfj/valveleft.png"
            alt=""
          />
          <!-- 下2 -->
          <img
            ref="vd2"
            style="
              position: absolute;
              width: 3%;
              top: 70%;
              left: 28.6%;
              display: none;
            "
            src="@/assets/tfj/valveleft.png"
            alt=""
          />
          <!-- 下3 -->
          <img
            ref="vd3"
            style="
              position: absolute;
              width: 3%;
              top: 70%;
              left: 45.6%;
              display: none;
            "
            src="@/assets/tfj/valveleft.png"
            alt=""
          />
          <!-- 下4 -->
          <img
            ref="vd4"
            style="
              position: absolute;
              width: 3%;
              top: 70%;
              left: 62.6%;
              display: none;
            "
            src="@/assets/tfj/valveleft.png"
            alt=""
          />
          <!-- 主1 -->
          <img
            ref="vm1"
            style="
              position: absolute;
              width: 3.5%;
              top: 82%;
              left: 70.3%;
              display: none;
            "
            src="@/assets/tfj/valveup.png"
            alt=""
          />
          <!-- 主2 -->
          <img
            ref="vm2"
            style="
              position: absolute;
              width: 3.5%;
              top: 82%;
              left: 81.6%;
              display: none;
            "
            src="@/assets/tfj/valveup.png"
            alt=""
          />
        </div>
      </div>
      <div class="info">
        <el-descriptions title="实时数据" size="mini" :column="2" border>
          <el-descriptions-item label="管道压力" label-class-name="my-label">{{
            resultArray["PipelinePressure"].value
          }}</el-descriptions-item>

          <el-descriptions-item
            label="控制器状态"
            v-if="resultArray['PLC_Status'].value == 0"
            >正常</el-descriptions-item
          >
          <el-descriptions-item
            label="控制器状态"
            v-else
            content-class-name="my-content"
            >异常</el-descriptions-item
          >

          <el-descriptions-item label="已搅拌时间" v-if="using == false"
            >0</el-descriptions-item
          >
          <el-descriptions-item
            label="已搅拌时间"
            v-if="resultArray['K_RemainingFertilizer'].value > 0"
            >{{
              resultArray["K_AlreadyStirred"].value
            }}min</el-descriptions-item
          >
          <el-descriptions-item
            label="已搅拌时间"
            v-if="resultArray['N_RemainingFertilizer'].value > 0"
            >{{
              resultArray["N_AlreadyStirred"].value
            }}min</el-descriptions-item
          >
          <el-descriptions-item
            label="已搅拌时间"
            v-if="resultArray['P_RemainingFertilizer'].value > 0"
            >{{
              resultArray["P_AlreadyStirred"].value
            }}min</el-descriptions-item
          >

          <el-descriptions-item
            label="变频器状态"
            v-if="resultArray['InverterStatus'].value == 0"
            >正常</el-descriptions-item
          >
          <el-descriptions-item
            label="变频器状态"
            v-else
            content-class-name="my-content"
            >异常</el-descriptions-item
          >

          <el-descriptions-item label="已施肥时间" v-if="using == false"
            >0</el-descriptions-item
          >
          <el-descriptions-item
            label="已施肥时间"
            v-if="resultArray['K_RemainingFertilizer'].value > 0"
            >{{
              resultArray["K_AlreadyFertilized"].value
            }}min</el-descriptions-item
          >
          <el-descriptions-item
            label="已施肥时间"
            v-if="resultArray['N_RemainingFertilizer'].value > 0"
            >{{
              resultArray["N_AlreadyFertilized"].value
            }}min</el-descriptions-item
          >
          <el-descriptions-item
            label="已施肥时间"
            v-if="resultArray['P_RemainingFertilizer'].value > 0"
            >{{
              resultArray["P_AlreadyFertilized"].value
            }}min</el-descriptions-item
          >

          <el-descriptions-item label="当前频率"
            >{{
              Number(resultArray["OperatingFrequency"].value).toFixed(2)
            }}Hz</el-descriptions-item
          >

          <el-descriptions-item label="剩余施肥量" v-if="using == false"
            >0</el-descriptions-item
          >
          <el-descriptions-item
            label="剩余施肥量"
            v-if="resultArray['K_RemainingFertilizer'].value > 0"
            >{{
              Number(resultArray["K_RemainingFertilizer"].value).toFixed(2)
            }}L</el-descriptions-item
          >
          <el-descriptions-item
            label="剩余施肥量"
            v-if="resultArray['N_RemainingFertilizer'].value > 0"
            >{{
              Number(resultArray["N_RemainingFertilizer"].value).toFixed(2)
            }}L</el-descriptions-item
          >
          <el-descriptions-item
            label="剩余施肥量"
            v-if="resultArray['P_RemainingFertilizer'].value > 0"
            >{{
              Number(resultArray["P_RemainingFertilizer"].value).toFixed(2)
            }}L</el-descriptions-item
          >

          <el-descriptions-item label="设定频率"
            >{{ resultArray["SetFrequency"].value }}Hz</el-descriptions-item
          >

          <el-descriptions-item label="当前清洗时间"
            >{{
              resultArray["K_AlreadyCleaned"].value
            }}min</el-descriptions-item
          >

          <el-descriptions-item
            label="网络连接状态"
            v-if="resultArray['ScreenStatus'].value == 0"
            >正常</el-descriptions-item
          >
          <el-descriptions-item
            label="网络连接状态"
            v-else
            content-class-name="my-content"
            >异常</el-descriptions-item
          >

          <el-descriptions-item label="信号强度">{{
            resultArray["CSQ"].value
          }}</el-descriptions-item>

          <el-descriptions-item
            label="阿里云状态"
            v-if="resultArray['AliyunStatus'].value == 0"
            >正常</el-descriptions-item
          >
          <el-descriptions-item
            label="阿里云状态"
            v-else
            content-class-name="my-content"
            >异常</el-descriptions-item
          >
        </el-descriptions>
      </div>

      <div class="chartsBox">
        <div
          id="chartForTotal"
          ref="chartForTotal"
          style="width: 100%; height: 35vh;"
        ></div>
      </div>

      <!-- K罐控制框 -->
      <el-dialog
        :title="titlek"
        :visible.sync="openk"
        width="500px"
        append-to-body
      >
        <el-form ref="formk" :model="formk" :rules="rules" label-width="120px">
          <el-form-item
            label="搅拌时间(min)"
            prop="K_StiringTime"
            :rules="[
              { required: true, message: '搅拌时间不能为空' },
              { type: 'number', message: '搅拌时间必须为数字值' },
            ]"
          >
            <el-input
              type="K_StiringTime"
              v-model.number="formk.K_StiringTime"
              placeholder="请输入搅拌时间"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="施肥量(L)"
            prop="K_FertilizerAmount"
            :rules="[
              { required: true, message: '施肥量不能为空' },
              { type: 'number', message: '施肥量必须为数字值' },
            ]"
          >
            <el-input
              type="K_FertilizerAmount"
              v-model.number="formk.K_FertilizerAmount"
              placeholder="请输入施肥量"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="施肥密度(g/L)"
            prop="K_Density"
            :rules="[
              { required: true, message: '施肥密度不能为空' },
              { type: 'number', message: '施肥密度必须为数字值' },
            ]"
          >
            <el-input
              type="K_Density"
              v-model.number="formk.K_Density"
              placeholder="请输入施肥密度"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="施肥速度(Hz)"
            prop="SetFrequency"
            :rules="[
              { required: true, message: '施肥速度不能为空' },
              { type: 'number', message: '施肥速度必须为数字值' },
              {
                pattern: /^[1-9]$|^[1-4]\d$|^[5][0]$/,
                message: '速度在10-50之间',
              },
            ]"
          >
            <el-input
              type="SetFrequency"
              v-model.number="formk.SetFrequency"
              placeholder="请输入施肥速度(10-50之间)"
              autocomplete="off"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="submitFormK">启 动</el-button>
          <el-button @click="cancelk">取 消</el-button>
        </div>
      </el-dialog>

      <!-- N罐控制框 -->
      <el-dialog
        :title="titlen"
        :visible.sync="openn"
        width="500px"
        append-to-body
      >
        <el-form ref="formn" :model="formn" :rules="rules" label-width="120px">
          <el-form-item
            label="搅拌时间(min)"
            prop="N_StiringTime"
            :rules="[
              { required: true, message: '搅拌时间不能为空' },
              { type: 'number', message: '搅拌时间必须为数字值' },
            ]"
          >
            <el-input
              type="N_StiringTime"
              v-model.number="formn.N_StiringTime"
              placeholder="请输入搅拌时间"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="施肥量(L)"
            prop="N_FertilizerAmount"
            :rules="[
              { required: true, message: '施肥量不能为空' },
              { type: 'number', message: '施肥量必须为数字值' },
            ]"
          >
            <el-input
              type="N_FertilizerAmount"
              v-model.number="formn.N_FertilizerAmount"
              placeholder="请输入施肥量"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="施肥密度(g/L)"
            prop="N_Density"
            :rules="[
              { required: true, message: '施肥密度不能为空' },
              { type: 'number', message: '施肥密度必须为数字值' },
            ]"
          >
            <el-input
              type="N_Density"
              v-model.number="formn.N_Density"
              placeholder="请输入施肥密度"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="施肥速度(Hz)"
            prop="SetFrequency"
            :rules="[
              { required: true, message: '施肥速度不能为空' },
              { type: 'number', message: '施肥速度必须为数字值' },
              {
                pattern: /^[1-9]$|^[1-4]\d$|^[5][0]$/,
                message: '速度在0-50之间',
              },
            ]"
          >
            <el-input
              type="SetFrequency"
              v-model.number="formn.SetFrequency"
              placeholder="请输入施肥速度(10-50之间)"
              autocomplete="off"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="submitFormN">启 动</el-button>
          <el-button @click="canceln">取 消</el-button>
        </div>
      </el-dialog>

      <!-- P罐控制框 -->
      <el-dialog
        :title="titlep"
        :visible.sync="openp"
        width="500px"
        append-to-body
      >
        <el-form ref="formp" :model="formp" :rules="rules" label-width="120px">
          <el-form-item
            label="搅拌时间(min)"
            prop="P_StiringTime"
            :rules="[
              { required: true, message: '搅拌时间不能为空' },
              { type: 'number', message: '搅拌时间必须为数字值' },
            ]"
          >
            <el-input
              type="P_StiringTime"
              v-model.number="formp.P_StiringTime"
              placeholder="请输入搅拌时间"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="施肥量(L)"
            prop="P_FertilizerAmount"
            :rules="[
              { required: true, message: '施肥量不能为空' },
              { type: 'number', message: '施肥量必须为数字值' },
            ]"
          >
            <el-input
              type="P_FertilizerAmount"
              v-model.number="formp.P_FertilizerAmount"
              placeholder="请输入施肥量"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="施肥密度(g/L)"
            prop="P_Density"
            :rules="[
              { required: true, message: '施肥密度不能为空' },
              { type: 'number', message: '施肥密度必须为数字值' },
            ]"
          >
            <el-input
              type="P_Density"
              v-model.number="formp.P_Density"
              placeholder="请输入施肥密度"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="施肥速度(Hz)"
            prop="SetFrequency"
            :rules="[
              { required: true, message: '施肥速度不能为空' },
              { type: 'number', message: '施肥速度必须为数字值' },
              {
                pattern: /^[1-9]$|^[1-4]\d$|^[5][0]$/,
                message: '速度在10-50之间',
              },
            ]"
          >
            <el-input
              type="SetFrequency"
              v-model.number="formp.SetFrequency"
              placeholder="请输入施肥速度(10-50之间)"
              autocomplete="off"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="submitFormP">启 动</el-button>
          <el-button @click="cancelp">取 消</el-button>
        </div>
      </el-dialog>

      <!-- 清水罐控制框 -->
      <el-dialog
        :title="title"
        :visible.sync="open"
        width="500px"
        append-to-body
      >
        <el-form ref="form" :model="form" :rules="rules" label-width="120px">
          <el-form-item
            label="清水清洗时间"
            prop="CleaningTime"
            :rules="[
              { required: true, message: '清水清洗时间不能为空' },
              { type: 'number', message: '搅拌时间必须为数字值' },
            ]"
          >
            <el-input
              type="CleaningTime"
              v-model.number="form.CleaningTime"
              placeholder="请输入清水清洗时间"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="施肥速度"
            prop="SetFrequency"
            :rules="[
              { required: true, message: '施肥速度不能为空' },
              { type: 'number', message: '施肥速度必须为数字值' },
              {
                pattern: /^[1-9]$|^[1-4]\d$|^[5][0]$/,
                message: '速度在10-50之间',
              },
            ]"
          >
            <el-input
              type="SetFrequency"
              v-model.number="form.SetFrequency"
              placeholder="请输入施肥速度(10-50之间)"
              autocomplete="off"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="submitForm">启 动</el-button>
          <el-button @click="cancel">取 消</el-button>
        </div>
      </el-dialog>
    </div>
  </el-card>
</template>


<style>
#deliver-controller .box-card {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
}
#deliver-controller #pipeTop-shade {
  right: 22%;
  top: 5%;
  height: 5%;
  position: absolute;
  width: 67%;
  overflow: hidden;
}
#deliver-controller #pipeTop {
  width: 100%;
  height: 50%;
  top: 25%;
  position: relative;
  left: 100%;
  background: url(../../assets/tfj/pipeleft1.png) repeat-x;
}
#deliver-controller #pipeBottom-shade {
  right: 21%;
  top: 85%;
  height: 5%;
  position: absolute;
  width: 68%;
  overflow: hidden;
}
#deliver-controller #pipeBottom {
  width: 100%;
  height: 50%;
  position: relative;
  left: -100%;
  top: 25%;
  background: url(../../assets/tfj/pipeleft1.png) repeat-x;
}
#deliver-controller #pipeUp1-shade {
  width: 2%;
  height: 25%;
  position: absolute;
  top: 8%;
  left: 11%;
  overflow: hidden;
}
#deliver-controller #pipeUp1 {
  width: 60%;
  height: 100%;
  position: relative;
  top: -100%;
  left: 5%;
  background: url(../../assets/tfj/pipeup1.png) repeat-y;
}
#deliver-controller #pipeDown1-shade {
  width: 2%;
  height: 25%;
  position: absolute;
  top: 62%;
  left: 11%;
  overflow: hidden;
}
#deliver-controller #pipeDown1 {
  width: 60%;
  height: 100%;
  position: relative;
  top: 100%;
  left: 5%;
  background: url(../../assets/tfj/pipeup1.png) repeat-y;
}

#deliver-controller #pipeUp2-shade {
  width: 2%;
  height: 25%;
  position: absolute;
  top: 8%;
  left: 28%;
  overflow: hidden;
}
#deliver-controller #pipeUp2 {
  width: 60%;
  height: 100%;
  position: relative;
  top: -100%;
  left: 28%;
  background: url(../../assets/tfj/pipeup1.png) repeat-y;
}
#deliver-controller #pipeDown2-shade {
  width: 2%;
  height: 25%;
  position: absolute;
  top: 62%;
  left: 28%;
  overflow: hidden;
}
#deliver-controller #pipeDown2 {
  width: 60%;
  height: 100%;
  position: relative;
  top: 100%;
  left: 25%;
  background: url(../../assets/tfj/pipeup1.png) repeat-y;
}
#deliver-controller #pipeUp3-shade {
  width: 2%;
  height: 25%;
  position: absolute;
  top: 8%;
  left: 45%;
  overflow: hidden;
}
#deliver-controller #pipeUp3 {
  width: 60%;
  height: 100%;
  position: relative;
  top: -100%;
  left: 28%;
  background: url(../../assets/tfj/pipeup1.png) repeat-y;
}
#deliver-controller #pipeDown3-shade {
  width: 2%;
  height: 25%;
  position: absolute;
  top: 62%;
  left: 45%;
  overflow: hidden;
}
#deliver-controller #pipeDown3 {
  width: 60%;
  height: 100%;
  position: relative;
  top: 100%;
  left: 28%;
  background: url(../../assets/tfj/pipeup1.png) repeat-y;
}
#deliver-controller #pipeUp4-shade {
  width: 2%;
  height: 25%;
  position: absolute;
  top: 8%;
  left: 62%;
  overflow: hidden;
}
#deliver-controller #pipeUp4 {
  width: 60%;
  height: 100%;
  position: relative;
  top: -100%;
  left: 5%;
  background: url(../../assets/tfj/pipeup1.png) repeat-y;
}
#deliver-controller #pipeDown4-shade {
  width: 2%;
  height: 25%;
  position: absolute;
  top: 62%;
  left: 62%;
  overflow: hidden;
}
#deliver-controller #pipeDown4 {
  width: 60%;
  height: 100%;
  position: relative;
  top: 100%;
  left: 28%;
  background: url(../../assets/tfj/pipeup1.png) repeat-y;
}
#deliver-controller #pipeUp5-shade {
  width: 2%;
  height: 80%;
  position: absolute;
  top: 6%;
  left: 77%;
  overflow: hidden;
}
#deliver-controller #pipeUp5 {
  width: 60%;
  height: 100%;
  position: relative;
  top: -100%;
  left: 5%;
  background: url(../../assets/tfj/pipeup1.png) repeat-y;
}
#deliver-controller .entire {
  position: relative;
}
#deliver-controller .tfj-content {
  width: 80%;
  position: relative;
  overflow: hidden;
}
#deliver-controller .Nbox {
  cursor: pointer;
  width: 12%;
  height: 30%;
  position: absolute;
  top: 33%;
  left: 3%;
  background: rgba(255, 255, 255, 0);
}
#deliver-controller .Pbox {
  cursor: pointer;
  width: 12%;
  height: 30%;
  position: absolute;
  top: 33%;
  left: 23%;
  background: rgba(255, 255, 255, 0);
}
#deliver-controller .Kbox {
  cursor: pointer;
  width: 12%;
  height: 30%;
  position: absolute;
  top: 33%;
  left: 40%;
  background: rgba(255, 255, 255, 0);
}
#deliver-controller .Wbox {
  cursor: pointer;
  width: 12%;
  height: 30%;
  position: absolute;
  top: 33%;
  left: 57%;
  background: rgba(255, 255, 255, 0);
}
#deliver-controller .info {
  position: absolute;
  right: 2%;
  top: 2%;
}
#deliver-controller .my-label {
  background: #e1f3d8;
}

#deliver-controller .my-content {
  background: #fde2e2;
}

#deliver-controller .el-input{
    width: 90%;
    margin-left: 20px;
}

#deliver-controller .el-form-item__error{
    margin-left: 20px;
}

#deliver-controller .el-form-item{
    margin-bottom: 22px;
}
</style>

<script>
import * as echarts from 'echarts';

export default {
  data() {
    return {
      // 弹出层标题
      title: "",
      titlek: "",
      titlen: "",
      titlep: "",
      // 表单参数
      form: {},
      formk: {},
      formn: {},
      formp: {},
      historyform: {},
      // 表单校验
      rules: {},
      //infoList: [],
      resultArray: {},
      // 是否显示弹出层
      open: false,
      openk: false,
      openn: false,
      openp: false,
      using: false,
      Property: {},
      Propertyk: {},
      Propertyn: {},
      Propertyp: {},
      deviceName: "",
      productKey: "",
      tfjImg: "",
      // 查询参数
      queryParams: {
        deviceName: "",
        productKey: "",
      },
      nowKData: null,
      nowNData: null,
      nowPData: null,
      nowWData: null,
      polling: null,
      aniN: null,
      aniP: null,
      aniK: null,
      aniW: null,
      aniPlay: false,
      // 图表
      optionPress: {},
      chartPress: {},
      pressInterval: null,
      pressDataX: [],
      pressDataY: [],

      optionTotal: {},
      chartTotal: {},
      dataAmount: [],
      dataCount: [],
    };
  },
  created() {
    this.queryParams.deviceName = sessionStorage.getItem("deviceName");
    this.queryParams.productKey = sessionStorage.getItem("productKey");
  },
  mounted() {
    this.getList("U");
    this.pollStatus();
    this.chartForTotal();
  },
  methods: {
    // 实时数据
    getList(e, j) {
      let result = [
        {
          unit: "count",
          identifier: "FlushCount",
          dataType: "int",
          time: "1634358338612",
          value: "36",
          name: "清水冲洗次数",
        },
        {
          unit: "",
          identifier: "InverterStatus",
          dataType: "int",
          time: "1634358338613",
          value: "2",
          name: "变频器通讯状态",
        },
        {
          unit: "min",
          identifier: "K_AlreadyCleaned",
          dataType: "float",
          time: "1644296444947",
          value: "0",
          name: "K罐已清洗时间",
        },
        {
          unit: "min",
          identifier: "N_AlreadyCleaned",
          dataType: "float",
          time: "1644296444947",
          value: "0",
          name: "N罐已清洗时间",
        },
        {
          unit: "L",
          identifier: "N_RemainingFertilizer",
          dataType: "float",
          time: "1644296444947",
          value: "0",
          name: "N罐剩余施肥量",
        },
        {
          unit: "min",
          identifier: "P_AlreadyCleaned",
          dataType: "float",
          time: "1644296444947",
          value: "0",
          name: "P罐已清洗时间",
        },
        {
          unit: "min",
          identifier: "K_AlreadyFertilized",
          dataType: "float",
          time: "1644296444948",
          value: "0",
          name: "K罐已施肥时间",
        },
        {
          unit: "min",
          identifier: "K_AlreadyStirred",
          dataType: "float",
          time: "1644296444948",
          value: "0",
          name: "K罐已搅拌时间",
        },
        {
          unit: "L",
          identifier: "K_RemainingFertilizer",
          dataType: "float",
          time: "1644296444948",
          value: "0",
          name: "K罐剩余施肥量",
        },
        {
          unit: "min",
          identifier: "N_AlreadyFertilized",
          dataType: "float",
          time: "1644296444948",
          value: "0",
          name: "N罐已施肥时间",
        },
        {
          unit: "min",
          identifier: "N_AlreadyStirred",
          dataType: "float",
          time: "1644296444948",
          value: "0",
          name: "N罐已搅拌时间",
        },
        {
          unit: "",
          identifier: "PLC_Status",
          dataType: "int",
          time: "1644296444948",
          value: "0",
          name: "PLC通讯状态",
        },
        {
          unit: "min",
          identifier: "P_AlreadyFertilized",
          dataType: "float",
          time: "1644296444948",
          value: "0",
          name: "P罐已施肥时间",
        },
        {
          unit: "min",
          identifier: "P_AlreadyStirred",
          dataType: "float",
          time: "1644296444948",
          value: "0",
          name: "P罐已搅拌时间",
        },
        {
          unit: "L",
          identifier: "P_RemainingFertilizer",
          dataType: "float",
          time: "1644296444948",
          value: "0",
          name: "P罐剩余施肥量",
        },
        {
          unit: "g/L",
          identifier: "K_Density",
          dataType: "float",
          time: "1644296444949",
          value: "0",
          name: "K罐密度",
        },
        {
          unit: "count",
          identifier: "K_FertilizationCount",
          dataType: "int",
          time: "1644296444949",
          value: "0",
          name: "K罐累积施肥次数",
        },
        {
          unit: "",
          identifier: "MainOutValve",
          dataType: "bool",
          time: "1644296444949",
          value: "0",
          name: "主出液阀门",
        },
        {
          unit: "",
          identifier: "MainPumpValve",
          dataType: "bool",
          time: "1644296444949",
          value: "0",
          name: "主泵阀门",
        },
        {
          unit: "g/L",
          identifier: "N_Density",
          dataType: "float",
          time: "1644296444949",
          value: "0",
          name: "N罐密度",
        },
        {
          unit: "g/L",
          identifier: "P_Density",
          dataType: "float",
          time: "1644296444949",
          value: "0",
          name: "P罐密度",
        },
        {
          unit: "Pa",
          identifier: "PipelinePressure",
          dataType: "float",
          time: "1644296444949",
          value: "0",
          name: "主管道压力",
        },
        {
          unit: null,
          identifier: "IMEI",
          dataType: "text",
          time: "1644296444950",
          value: "0",
          name: "移动设备标识码",
        },
        {
          unit: null,
          identifier: "K_FertilizerAmount",
          dataType: "float",
          time: "1644296444950",
          value: "0",
          name: "K罐施肥量",
        },
        {
          unit: "min",
          identifier: "K_StiringTime",
          dataType: "int",
          time: "1644296444950",
          value: "0",
          name: "K罐搅拌时间",
        },
        {
          unit: null,
          identifier: "N_FertilizerAmount",
          dataType: "float",
          time: "1644296444950",
          value: "0",
          name: "N罐施肥量",
        },
        {
          unit: "min",
          identifier: "N_StiringTime",
          dataType: "int",
          time: "1644296444950",
          value: "0",
          name: "N罐搅拌时间",
        },
        {
          unit: null,
          identifier: "P_FertilizerAmount",
          dataType: "float",
          time: "1644296444950",
          value: "0",
          name: "P罐施肥量",
        },
        {
          unit: "min",
          identifier: "P_StiringTime",
          dataType: "int",
          time: "1644296444950",
          value: "0",
          name: "P罐搅拌时间",
        },
        {
          unit: null,
          identifier: "SerialNumber",
          dataType: "text",
          time: "1644296444950",
          value: "0",
          name: "唯一序列号",
        },
        {
          unit: null,
          identifier: "CSQ",
          dataType: "int",
          time: "1644296444951",
          value: "0",
          name: "信号强度",
        },
        {
          unit: null,
          identifier: "AliyunStatus",
          dataType: "int",
          time: "1644296444952",
          value: "0",
          name: "阿里云通讯状态",
        },
        {
          unit: "count",
          identifier: "Count",
          dataType: "int",
          time: "1644296444952",
          value: "347",
          name: "上传次数",
        },
        {
          unit: null,
          identifier: "ICCID",
          dataType: "text",
          time: "1644296444952",
          value: "0",
          name: "SIM卡号",
        },
        {
          unit: "min",
          identifier: "SRuntime",
          dataType: "int",
          time: "1644296444952",
          value: "29",
          name: "物联网盒子运行时间",
        },
        {
          unit: null,
          identifier: "VS",
          dataType: "text",
          time: "1644296444952",
          value: "模拟量继电器-20210726",
          name: "版本",
        },
        {
          unit: null,
          identifier: "imei",
          dataType: "text",
          time: "1644296444952",
          value: "0",
          name: "移动设备识别号",
        },
        {
          unit: "",
          identifier: "K_Control",
          dataType: "bool",
          time: "1644296444953",
          value: "0",
          name: "K肥罐控制",
        },
        {
          unit: "L",
          identifier: "K_SumFertilizerAmount",
          dataType: "float",
          time: "1644296444953",
          value: "0",
          name: "K罐累积施肥量",
        },
        {
          unit: "",
          identifier: "N_Control",
          dataType: "bool",
          time: "1644296444953",
          value: "0",
          name: "N肥罐控制",
        },
        {
          unit: "count",
          identifier: "N_FertilizationCount",
          dataType: "int",
          time: "1644296444953",
          value: "0",
          name: "N罐累积施肥次数",
        },
        {
          unit: "L",
          identifier: "N_SumFertilizerAmount",
          dataType: "float",
          time: "1644296444953",
          value: "0",
          name: "N罐累积施肥量",
        },
        {
          unit: "",
          identifier: "P_Control",
          dataType: "bool",
          time: "1644296444953",
          value: "0",
          name: "P肥罐控制",
        },
        {
          unit: "count",
          identifier: "P_FertilizationCount",
          dataType: "int",
          time: "1644296444953",
          value: "0",
          name: "P罐累积施肥次数",
        },
        {
          unit: "L",
          identifier: "P_SumFertilizerAmount",
          dataType: "float",
          time: "1644296444953",
          value: "0",
          name: "P罐累积施肥量",
        },
        {
          unit: "s",
          identifier: "ReportingPeriod",
          dataType: "int",
          time: "1644296444953",
          value: "600",
          name: "设置上报周期",
        },
        {
          unit: "min",
          identifier: "CleaningTime",
          dataType: "int",
          time: "1644296444954",
          value: "0",
          name: "清水清洗时间",
        },
        {
          unit: "L",
          identifier: "Flush",
          dataType: "float",
          time: "1644296444954",
          value: "0",
          name: "累积冲水量",
        },
        {
          unit: "",
          identifier: "K_InValve",
          dataType: "bool",
          time: "1644296444954",
          value: "0",
          name: "K罐进液阀门",
        },
        {
          unit: "Hz",
          identifier: "OperatingFrequency",
          dataType: "float",
          time: "1644296444954",
          value: "0",
          name: "变频器运行频率",
        },
        {
          unit: "",
          identifier: "ScreenStatus",
          dataType: "int",
          time: "1644296444954",
          value: "0",
          name: "屏幕通讯状态",
        },
        {
          unit: "Hz",
          identifier: "SetFrequency",
          dataType: "float",
          time: "1644296444954",
          value: "0",
          name: "变频器频率设定",
        },
        {
          unit: "",
          identifier: "WaterControl",
          dataType: "bool",
          time: "1644296444954",
          value: "0",
          name: "清水罐控制",
        },
        {
          unit: "",
          identifier: "K_OutValve",
          dataType: "bool",
          time: "1644296444955",
          value: "0",
          name: "K罐出液阀门",
        },
        {
          unit: "",
          identifier: "N_InValve",
          dataType: "bool",
          time: "1644296444955",
          value: "0",
          name: "N罐进液阀门",
        },
        {
          unit: "",
          identifier: "N_OutValve",
          dataType: "bool",
          time: "1644296444955",
          value: "0",
          name: "N罐出液阀门",
        },
        {
          unit: "",
          identifier: "P_InValve",
          dataType: "bool",
          time: "1644296444955",
          value: "0",
          name: "P罐进液阀门",
        },
        {
          unit: "",
          identifier: "P_OutValve",
          dataType: "bool",
          time: "1644296444955",
          value: "0",
          name: "P罐出液阀门",
        },
        {
          unit: "",
          identifier: "Water_OutValve",
          dataType: "bool",
          time: "1644296444955",
          value: "0",
          name: "清水罐出液阀门",
        },
        {
          unit: "",
          identifier: "InverterControl",
          dataType: "int",
          time: null,
          value: null,
          name: "变频器控制",
        },
        {
          unit: "",
          identifier: "GeoLocation",
          dataType: "struct",
          time: null,
          value: null,
          name: "地理位置",
        },
      ];
      this.resultArray = {};
      for (let i = 0; i < result.length; i++) {
        this.resultArray[result[i].identifier] = result[i];
      }
      if (e == "K") {
        if (this.resultArray["K_AlreadyStirred"].value == 0 && j > 5) {
          this.closeAniForK();
          this.aniForKFert();
        }
        if (this.resultArray["K_RemainingFertilizer"].value == 0 && j > 5) {
          clearInterval(this.nowKData);
          clearInterval(this.pressInterval);
          this.closeAniForK();
          this.msgSuccess("施肥完成,结束运行");
          this.using = false;
          this.pollStatus();
          this.backUpToServer();
        }
      } else if (e == "N") {
        if (this.resultArray["N_AlreadyStirred"].value == 0 && j > 5) {
          this.closeAniForN();
          this.aniForNFert();
        }
        if (this.resultArray["N_RemainingFertilizer"].value == 0 && j > 5) {
          clearInterval(this.nowNData);
          clearInterval(this.pressInterval);
          this.closeAniForN();
          this.msgSuccess("施肥完成,结束运行");
          this.using = false;
          this.pollStatus();
          this.backUpToServer();
        }
      } else if (e == "P") {
        if (this.resultArray["P_AlreadyStirred"].value == 0 && j > 5) {
          this.closeAniForP();
          this.aniForPFert();
        }
        if (this.resultArray["P_RemainingFertilizer"].value == 0 && j > 5) {
          clearInterval(this.nowPData);
          clearInterval(this.pressInterval);
          this.closeAniForP();
          this.msgSuccess("施肥完成,结束运行");
          this.using = false;
          this.pollStatus();
          this.backUpToServer();
        }
      } else if (e == "W") {
        if (this.resultArray["K_AlreadyCleaned"].value == 0 && j > 5) {
          clearInterval(this.nowWData);
          clearInterval(this.pressInterval);
          this.closeAniForW();
          this.msgSuccess("清洗完成,结束运行");
          this.using = false;
          this.pollStatus();
          this.backUpToServer();
        }
      }
    },
    getHistoryData() {},
    //总施肥量和施肥次数备份到数据库
    backUpToServer() {
      this.$message.success("已完成备份！");
    },
    //轮询设备状态，若正在运行需要同步实时数据
    pollStatus() {
      this.polling = setInterval(() => {
        let fakeArr = [
          {
            unit: "count",
            identifier: "FlushCount",
            dataType: "int",
            time: "1634358338612",
            value: "36",
            name: "清水冲洗次数",
          },
          {
            unit: "",
            identifier: "InverterStatus",
            dataType: "int",
            time: "1634358338613",
            value: "2",
            name: "变频器通讯状态",
          },
          {
            unit: "min",
            identifier: "K_AlreadyCleaned",
            dataType: "float",
            time: "1644296444947",
            value: "0",
            name: "K罐已清洗时间",
          },
          {
            unit: "min",
            identifier: "N_AlreadyCleaned",
            dataType: "float",
            time: "1644296444947",
            value: "0",
            name: "N罐已清洗时间",
          },
          {
            unit: "L",
            identifier: "N_RemainingFertilizer",
            dataType: "float",
            time: "1644296444947",
            value: "0",
            name: "N罐剩余施肥量",
          },
          {
            unit: "min",
            identifier: "P_AlreadyCleaned",
            dataType: "float",
            time: "1644296444947",
            value: "0",
            name: "P罐已清洗时间",
          },
          {
            unit: "min",
            identifier: "K_AlreadyFertilized",
            dataType: "float",
            time: "1644296444948",
            value: "0",
            name: "K罐已施肥时间",
          },
          {
            unit: "min",
            identifier: "K_AlreadyStirred",
            dataType: "float",
            time: "1644296444948",
            value: "0",
            name: "K罐已搅拌时间",
          },
          {
            unit: "L",
            identifier: "K_RemainingFertilizer",
            dataType: "float",
            time: "1644296444948",
            value: "0",
            name: "K罐剩余施肥量",
          },
          {
            unit: "min",
            identifier: "N_AlreadyFertilized",
            dataType: "float",
            time: "1644296444948",
            value: "0",
            name: "N罐已施肥时间",
          },
          {
            unit: "min",
            identifier: "N_AlreadyStirred",
            dataType: "float",
            time: "1644296444948",
            value: "0",
            name: "N罐已搅拌时间",
          },
          {
            unit: "",
            identifier: "PLC_Status",
            dataType: "int",
            time: "1644296444948",
            value: "0",
            name: "PLC通讯状态",
          },
          {
            unit: "min",
            identifier: "P_AlreadyFertilized",
            dataType: "float",
            time: "1644296444948",
            value: "0",
            name: "P罐已施肥时间",
          },
          {
            unit: "min",
            identifier: "P_AlreadyStirred",
            dataType: "float",
            time: "1644296444948",
            value: "0",
            name: "P罐已搅拌时间",
          },
          {
            unit: "L",
            identifier: "P_RemainingFertilizer",
            dataType: "float",
            time: "1644296444948",
            value: "0",
            name: "P罐剩余施肥量",
          },
          {
            unit: "g/L",
            identifier: "K_Density",
            dataType: "float",
            time: "1644296444949",
            value: "0",
            name: "K罐密度",
          },
          {
            unit: "count",
            identifier: "K_FertilizationCount",
            dataType: "int",
            time: "1644296444949",
            value: "0",
            name: "K罐累积施肥次数",
          },
          {
            unit: "",
            identifier: "MainOutValve",
            dataType: "bool",
            time: "1644296444949",
            value: "0",
            name: "主出液阀门",
          },
          {
            unit: "",
            identifier: "MainPumpValve",
            dataType: "bool",
            time: "1644296444949",
            value: "0",
            name: "主泵阀门",
          },
          {
            unit: "g/L",
            identifier: "N_Density",
            dataType: "float",
            time: "1644296444949",
            value: "0",
            name: "N罐密度",
          },
          {
            unit: "g/L",
            identifier: "P_Density",
            dataType: "float",
            time: "1644296444949",
            value: "0",
            name: "P罐密度",
          },
          {
            unit: "Pa",
            identifier: "PipelinePressure",
            dataType: "float",
            time: "1644296444949",
            value: "0",
            name: "主管道压力",
          },
          {
            unit: null,
            identifier: "IMEI",
            dataType: "text",
            time: "1644296444950",
            value: "0",
            name: "移动设备标识码",
          },
          {
            unit: null,
            identifier: "K_FertilizerAmount",
            dataType: "float",
            time: "1644296444950",
            value: "0",
            name: "K罐施肥量",
          },
          {
            unit: "min",
            identifier: "K_StiringTime",
            dataType: "int",
            time: "1644296444950",
            value: "0",
            name: "K罐搅拌时间",
          },
          {
            unit: null,
            identifier: "N_FertilizerAmount",
            dataType: "float",
            time: "1644296444950",
            value: "0",
            name: "N罐施肥量",
          },
          {
            unit: "min",
            identifier: "N_StiringTime",
            dataType: "int",
            time: "1644296444950",
            value: "0",
            name: "N罐搅拌时间",
          },
          {
            unit: null,
            identifier: "P_FertilizerAmount",
            dataType: "float",
            time: "1644296444950",
            value: "0",
            name: "P罐施肥量",
          },
          {
            unit: "min",
            identifier: "P_StiringTime",
            dataType: "int",
            time: "1644296444950",
            value: "0",
            name: "P罐搅拌时间",
          },
          {
            unit: null,
            identifier: "SerialNumber",
            dataType: "text",
            time: "1644296444950",
            value: "0",
            name: "唯一序列号",
          },
          {
            unit: null,
            identifier: "CSQ",
            dataType: "int",
            time: "1644296444951",
            value: "0",
            name: "信号强度",
          },
          {
            unit: null,
            identifier: "AliyunStatus",
            dataType: "int",
            time: "1644296444952",
            value: "0",
            name: "阿里云通讯状态",
          },
          {
            unit: "count",
            identifier: "Count",
            dataType: "int",
            time: "1644296444952",
            value: "347",
            name: "上传次数",
          },
          {
            unit: null,
            identifier: "ICCID",
            dataType: "text",
            time: "1644296444952",
            value: "0",
            name: "SIM卡号",
          },
          {
            unit: "min",
            identifier: "SRuntime",
            dataType: "int",
            time: "1644296444952",
            value: "29",
            name: "物联网盒子运行时间",
          },
          {
            unit: null,
            identifier: "VS",
            dataType: "text",
            time: "1644296444952",
            value: "模拟量继电器-20210726",
            name: "版本",
          },
          {
            unit: null,
            identifier: "imei",
            dataType: "text",
            time: "1644296444952",
            value: "0",
            name: "移动设备识别号",
          },
          {
            unit: "",
            identifier: "K_Control",
            dataType: "bool",
            time: "1644296444953",
            value: "0",
            name: "K肥罐控制",
          },
          {
            unit: "L",
            identifier: "K_SumFertilizerAmount",
            dataType: "float",
            time: "1644296444953",
            value: "0",
            name: "K罐累积施肥量",
          },
          {
            unit: "",
            identifier: "N_Control",
            dataType: "bool",
            time: "1644296444953",
            value: "0",
            name: "N肥罐控制",
          },
          {
            unit: "count",
            identifier: "N_FertilizationCount",
            dataType: "int",
            time: "1644296444953",
            value: "0",
            name: "N罐累积施肥次数",
          },
          {
            unit: "L",
            identifier: "N_SumFertilizerAmount",
            dataType: "float",
            time: "1644296444953",
            value: "0",
            name: "N罐累积施肥量",
          },
          {
            unit: "",
            identifier: "P_Control",
            dataType: "bool",
            time: "1644296444953",
            value: "0",
            name: "P肥罐控制",
          },
          {
            unit: "count",
            identifier: "P_FertilizationCount",
            dataType: "int",
            time: "1644296444953",
            value: "0",
            name: "P罐累积施肥次数",
          },
          {
            unit: "L",
            identifier: "P_SumFertilizerAmount",
            dataType: "float",
            time: "1644296444953",
            value: "0",
            name: "P罐累积施肥量",
          },
          {
            unit: "s",
            identifier: "ReportingPeriod",
            dataType: "int",
            time: "1644296444953",
            value: "600",
            name: "设置上报周期",
          },
          {
            unit: "min",
            identifier: "CleaningTime",
            dataType: "int",
            time: "1644296444954",
            value: "0",
            name: "清水清洗时间",
          },
          {
            unit: "L",
            identifier: "Flush",
            dataType: "float",
            time: "1644296444954",
            value: "0",
            name: "累积冲水量",
          },
          {
            unit: "",
            identifier: "K_InValve",
            dataType: "bool",
            time: "1644296444954",
            value: "0",
            name: "K罐进液阀门",
          },
          {
            unit: "Hz",
            identifier: "OperatingFrequency",
            dataType: "float",
            time: "1644296444954",
            value: "0",
            name: "变频器运行频率",
          },
          {
            unit: "",
            identifier: "ScreenStatus",
            dataType: "int",
            time: "1644296444954",
            value: "0",
            name: "屏幕通讯状态",
          },
          {
            unit: "Hz",
            identifier: "SetFrequency",
            dataType: "float",
            time: "1644296444954",
            value: "0",
            name: "变频器频率设定",
          },
          {
            unit: "",
            identifier: "WaterControl",
            dataType: "bool",
            time: "1644296444954",
            value: "0",
            name: "清水罐控制",
          },
          {
            unit: "",
            identifier: "K_OutValve",
            dataType: "bool",
            time: "1644296444955",
            value: "0",
            name: "K罐出液阀门",
          },
          {
            unit: "",
            identifier: "N_InValve",
            dataType: "bool",
            time: "1644296444955",
            value: "0",
            name: "N罐进液阀门",
          },
          {
            unit: "",
            identifier: "N_OutValve",
            dataType: "bool",
            time: "1644296444955",
            value: "0",
            name: "N罐出液阀门",
          },
          {
            unit: "",
            identifier: "P_InValve",
            dataType: "bool",
            time: "1644296444955",
            value: "0",
            name: "P罐进液阀门",
          },
          {
            unit: "",
            identifier: "P_OutValve",
            dataType: "bool",
            time: "1644296444955",
            value: "0",
            name: "P罐出液阀门",
          },
          {
            unit: "",
            identifier: "Water_OutValve",
            dataType: "bool",
            time: "1644296444955",
            value: "0",
            name: "清水罐出液阀门",
          },
          {
            unit: "",
            identifier: "InverterControl",
            dataType: "int",
            time: null,
            value: null,
            name: "变频器控制",
          },
          {
            unit: "",
            identifier: "GeoLocation",
            dataType: "struct",
            time: null,
            value: null,
            name: "地理位置",
          },
        ];
        let result = fakeArr;
        let obj = {};
        for (let i = 0; i < result.length; i++) {
          obj[result[i].identifier] = result[i];
        }
        if (obj["K_RemainingFertilizer"].value != 0) {
          this.msgError("设备启用中……");
          this.using = true;
          clearInterval(this.polling);
          this.aniForK();
          let count = 1;
          this.nowKData = setInterval(() => {
            this.getList("K", count);
            count++;
          }, 3000);
        } else if (obj["N_RemainingFertilizer"].value != 0) {
          this.msgError("设备启用中……");
          this.using = true;
          clearInterval(this.polling);
          this.aniForN();
          let count = 1;
          this.nowNData = setInterval(() => {
            this.getList("N", count);
            count++;
          }, 3000);
        } else if (obj["P_RemainingFertilizer"].value != 0) {
          this.msgError("设备启用中……");
          this.using = true;
          clearInterval(this.polling);
          this.aniForP();
          let count = 1;
          this.nowPData = setInterval(() => {
            this.getList("P", count);
            count++;
          }, 3000);
        } else if (obj["K_AlreadyCleaned"].value != 0) {
          this.msgError("设备启用中……");
          this.using = true;
          clearInterval(this.polling);
          this.aniForWFert();
          let count = 1;
          this.nowWData = setInterval(() => {
            this.getList("W", count);
            count++;
          }, 3000);
        }
      }, 5000);
    },
    chartForTotal() {
      this.dataAmount = [];
      this.dataCount = [];
      setTimeout(() => {
        let result = {
          searchValue: null,
          createBy: null,
          createTime: null,
          updateBy: null,
          updateTime: null,
          remark: null,
          params: {},
          id: 1,
          deviceName: "2200202104070003",
          productKey: "a1Je3oN7w9G",
          nSumFertilizerAmount: "313",
          nFertilizationCount: "27",
          pSumFertilizerAmount: "246",
          pFertilizationCount: "16",
          kSumFertilizerAmount: "644",
          kFertilizationCount: "35",
          flush: "429",
          flushCount: "19",
        };
        this.dataAmount.push(result.nSumFertilizerAmount);
        this.dataAmount.push(result.pSumFertilizerAmount);
        this.dataAmount.push(result.kSumFertilizerAmount);
        this.dataAmount.push(result.flush);
        this.dataCount.push(result.nFertilizationCount);
        this.dataCount.push(result.pFertilizationCount);
        this.dataCount.push(result.kFertilizationCount);
        this.dataCount.push(result.flushCount);
        let optionTotal = {
          title: {
            text: "累积施肥(冲水)统计",
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
          },
          legend: {},
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          xAxis: {
            type: "value",
            boundaryGap: [0, 0.01],
          },
          yAxis: {
            type: "category",
            data: ["N", "P", "K", "清水"],
          },
          series: [
            {
              name: "累积量",
              type: "bar",
              data: this.dataAmount,
            },
            {
              name: "累积次数",
              type: "bar",
              data: this.dataCount,
            },
          ],
        };
        let chartDom = document.getElementById('chartForTotal');
        let myChart = echarts.init(chartDom);
        optionTotal && myChart.setOption(optionTotal, true);
      }, 2000);
    },
    // k罐启动
    startUpk() {
      this.resetk();
      this.openk = true;
      this.titlek = "K罐参数设置";
    },
    // 表单重置
    resetk() {
      this.formk = {
        K_StiringTime: null,
        K_FertilizerAmount: null,
        K_Density: null,
        SetFrequency: null,
      };
    },
    /** 启动按钮 */
    submitFormK() {
      if (this.using == true) {
        this.msgError("设备工作中…");
        this.openk = false;
        return;
      }
      this.$message.success("启动成功！");
    },
    // 取消按钮
    cancelk() {
      this.openk = false;
      this.resetk();
    },
    // n罐启动
    startUpn() {
      this.resetn();
      this.openn = true;
      this.titlen = "N罐参数设置";
    },
    // 表单重置
    resetn() {
      this.formn = {
        N_StiringTime: null,
        N_FertilizerAmount: null,
        N_Density: null,
        SetFrequency: null,
      };
    },
    /** 启动按钮 */
    submitFormN() {
      if (this.using == true) {
        this.msgError("设备工作中…");
        this.openn = false;
        return;
      }
      this.$message.success("启动成功！");
    },
    // 取消按钮
    canceln() {
      this.openn = false;
      this.resetn();
    },
    // p罐启动
    startUpp() {
      this.resetp();
      this.openp = true;
      this.titlep = "P罐参数设置";
    },
    // 表单重置
    resetp() {
      this.formp = {
        P_StiringTime: null,
        P_FertilizerAmount: null,
        P_Density: null,
        SetFrequency: null,
      };
    },
    /** 启动按钮 */
    submitFormP() {
      if (this.using == true) {
        this.msgError("设备工作中…");
        this.openp = false;
        return;
      }
      this.$message.success("启动成功！");
    },
    // 取消按钮
    cancelp() {
      this.openp = false;
      this.resetp();
    },

    startUp() {
      this.reset();
      this.open = true;
      this.title = "清水罐参数设置";
    },
    // 表单重置
    reset() {
      this.form = {
        CleaningTime: null,
        SetFrequency: null,
      };
    },
    /** 启动按钮 */
    submitForm() {
      if (this.using == true) {
        this.msgError("设备工作中…");
        this.open = false;
        return;
      }
      this.$message.success("启动成功！");
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    //N罐运行动画(搅拌)
    aniForN() {
      this.closeAniForN();
      this.closeAniForP();
      this.closeAniForK();
      this.closeAniForW();
      if (this.aniPlay == true) {
        return;
      }
      this.aniPlay == true;
      var pipeTopShade = this.$refs.pipeTopShade;
      var pipeBottomShade = this.$refs.pipeBottomShade;
      var pipeTop = this.$refs.pipeTop;
      var pipeBottom = this.$refs.pipeBottom;
      var pipeUp1 = this.$refs.pipeUp1;
      var pipeDown1 = this.$refs.pipeDown1;
      var pipeUp5 = this.$refs.pipeUp5;
      var vu1 = this.$refs.vu1;
      var vd1 = this.$refs.vd1;
      var vm1 = this.$refs.vm1;
      vu1.style.display = "block";
      vd1.style.display = "block";
      vm1.style.display = "block";
      pipeTopShade.style.width = 67 + "%";
      pipeBottomShade.style.width = 68 + "%";
      //顶部横管道初始位置
      pipeTop.style.left = 100 + "%";
      //底部横管道初始位置
      pipeBottom.style.left = -100 + "%";
      //顶部纵管道1初始位置
      pipeUp1.style.top = -100 + "%";
      //底部纵管道1初始位置
      pipeDown1.style.top = 100 + "%";
      //顶部纵管道5初始位置
      pipeUp5.style.top = 100 + "%";
      let i = 100; //动画演出长度
      let m = 100;
      let k = 100;
      let j = 100; //动画播放速度
      this.aniN = setInterval(() => {
        if (j >= 67 && j <= 100) {
          pipeUp1.style.top = -100 + "%";
          pipeBottom.style.left = -i + "%";
          pipeDown1.style.top = 0 + "%";
          i = i - 3;
          j = j - 1;
        } else if (j >= 34 && j <= 66) {
          pipeUp5.style.top = m - 3 + "%";
          m = m - 3;
          j = j - 1;
        } else if (j >= 1 && j <= 33) {
          pipeUp1.style.top = -100 + "%";
          pipeTop.style.left = k - 3 + "%";
          k = k - 3;
          j = j - 1;
        } else {
          pipeTop.style.left = 100 + "%";
          pipeBottom.style.left = 100 + "%";
          pipeUp1.style.top = 0 + "%";
          pipeDown1.style.top = 100 + "%";
          pipeUp5.style.top = 100 + "%";
          i = 100;
          m = 100;
          k = 100;
          j = 100;
        }
      }, 100);
    },

    //P罐运行动画(搅拌)
    aniForP() {
      this.closeAniForN();
      this.closeAniForP();
      this.closeAniForK();
      this.closeAniForW();
      if (this.aniPlay == true) {
        return;
      }
      this.aniPlay == true;
      var pipeTopShade = this.$refs.pipeTopShade;
      var pipeBottomShade = this.$refs.pipeBottomShade;
      var pipeTop = this.$refs.pipeTop;
      var pipeBottom = this.$refs.pipeBottom;
      var pipeUp2 = this.$refs.pipeUp2;
      var pipeDown2 = this.$refs.pipeDown2;
      var pipeUp5 = this.$refs.pipeUp5;
      var vu2 = this.$refs.vu2;
      var vd2 = this.$refs.vd2;
      var vm1 = this.$refs.vm1;
      vu2.style.display = "block";
      vd2.style.display = "block";
      vm1.style.display = "block";
      pipeTopShade.style.width = 49 + "%";
      pipeBottomShade.style.width = 50.5 + "%";
      //顶部横管道初始位置
      pipeTop.style.left = 100 + "%";
      //底部横管道初始位置
      pipeBottom.style.left = -100 + "%";
      //顶部纵管道2初始位置
      pipeUp2.style.top = -100 + "%";
      //底部纵管道2初始位置
      pipeDown2.style.top = 100 + "%";
      //顶部纵管道5初始位置
      pipeUp5.style.top = 100 + "%";
      let i = 100; //动画演出长度
      let m = 100;
      let k = 100;
      let j = 100; //动画播放速度
      this.aniP = setInterval(() => {
        if (j >= 67 && j <= 100) {
          pipeUp2.style.top = -100 + "%";
          pipeBottom.style.left = -i + "%";
          pipeDown2.style.top = 0 + "%";
          i = i - 3;
          j = j - 1;
        } else if (j >= 34 && j <= 66) {
          pipeUp5.style.top = m - 3 + "%";
          m = m - 3;
          j = j - 1;
        } else if (j >= 1 && j <= 33) {
          pipeUp2.style.top = -100 + "%";
          pipeTop.style.left = k - 3 + "%";
          k = k - 3;
          j = j - 1;
        } else {
          pipeTop.style.left = 100 + "%";
          pipeBottom.style.left = 100 + "%";
          pipeUp2.style.top = 0 + "%";
          pipeDown2.style.top = 100 + "%";
          pipeUp5.style.top = 100 + "%";
          i = 100;
          m = 100;
          k = 100;
          j = 100;
        }
      }, 100);
    },

    //K罐运行动画(搅拌)
    aniForK() {
      this.closeAniForN();
      this.closeAniForP();
      this.closeAniForK();
      this.closeAniForW();
      if (this.aniPlay == true) {
        return;
      }
      this.aniPlay == true;
      var pipeTopShade = this.$refs.pipeTopShade;
      var pipeBottomShade = this.$refs.pipeBottomShade;
      var pipeTop = this.$refs.pipeTop;
      var pipeBottom = this.$refs.pipeBottom;
      var pipeUp3 = this.$refs.pipeUp3;
      var pipeDown3 = this.$refs.pipeDown3;
      var pipeUp5 = this.$refs.pipeUp5;
      var vu3 = this.$refs.vu3;
      var vd3 = this.$refs.vd3;
      var vm1 = this.$refs.vm1;
      vu3.style.display = "block";
      vd3.style.display = "block";
      vm1.style.display = "block";
      pipeTopShade.style.width = 32 + "%";
      pipeBottomShade.style.width = 33.5 + "%";
      //顶部横管道初始位置
      pipeTop.style.left = 100 + "%";
      //底部横管道初始位置
      pipeBottom.style.left = -100 + "%";
      //顶部纵管道3初始位置
      pipeUp3.style.top = -100 + "%";
      //底部纵管道3初始位置
      pipeDown3.style.top = 100 + "%";
      //顶部纵管道5初始位置
      pipeUp5.style.top = 100 + "%";
      let i = 100; //动画演出长度
      let m = 100;
      let k = 100;
      let j = 100; //动画播放速度
      this.aniK = setInterval(() => {
        if (j >= 67 && j <= 100) {
          pipeUp3.style.top = -100 + "%";
          pipeBottom.style.left = -i + "%";
          pipeDown3.style.top = 0 + "%";
          i = i - 3;
          j = j - 1;
        } else if (j >= 34 && j <= 66) {
          pipeUp5.style.top = m - 3 + "%";
          m = m - 3;
          j = j - 1;
        } else if (j >= 1 && j <= 33) {
          pipeUp3.style.top = -100 + "%";
          pipeTop.style.left = k - 3 + "%";
          k = k - 3;
          j = j - 1;
        } else {
          pipeTop.style.left = 100 + "%";
          pipeBottom.style.left = 100 + "%";
          pipeUp3.style.top = 0 + "%";
          pipeDown3.style.top = 100 + "%";
          pipeUp5.style.top = 100 + "%";
          i = 100;
          m = 100;
          k = 100;
          j = 100;
        }
      }, 100);
    },

    //N罐运行动画(施肥)
    aniForNFert() {
      this.closeAniForN();
      this.closeAniForP();
      this.closeAniForK();
      this.closeAniForW();
      if (this.aniPlay == true) {
        return;
      }
      this.aniPlay == true;
      var pipeBottomShade = this.$refs.pipeBottomShade;
      var pipeBottom = this.$refs.pipeBottom;
      var pipeDown1 = this.$refs.pipeDown1;
      var vd1 = this.$refs.vd1;
      var vm1 = this.$refs.vm1;
      var vm2 = this.$refs.vm2;
      var arrow = this.$refs.arrow;
      vd1.style.display = "block";
      vm1.style.display = "block";
      vm2.style.display = "block";
      arrow.style.display = "block";
      //pipeTopShade.style.width=67+'%'
      pipeBottomShade.style.width = 68 + "%";
      //底部横管道初始位置
      pipeBottom.style.left = -100 + "%";
      //底部纵管道1初始位置
      pipeDown1.style.top = 100 + "%";
      let i = 100; //动画演出长度
      this.aniN = setInterval(() => {
        if (i > 0) {
          pipeBottom.style.left = -i + "%";
          pipeDown1.style.top = 0 + "%";
          i = i - 3;
        } else {
          pipeBottom.style.left = 100 + "%";
          pipeDown1.style.top = 100 + "%";
          i = 100;
        }
      }, 100);
    },

    //P罐运行动画(施肥)  49  32
    aniForPFert() {
      this.closeAniForN();
      this.closeAniForP();
      this.closeAniForK();
      this.closeAniForW();
      if (this.aniPlay == true) {
        return;
      }
      this.aniPlay == true;
      var pipeBottomShade = this.$refs.pipeBottomShade;
      var pipeBottom = this.$refs.pipeBottom;
      var pipeDown2 = this.$refs.pipeDown2;
      var vd2 = this.$refs.vd2;
      var vm1 = this.$refs.vm1;
      var vm2 = this.$refs.vm2;
      var arrow = this.$refs.arrow;
      vd2.style.display = "block";
      vm1.style.display = "block";
      vm2.style.display = "block";
      arrow.style.display = "block";
      pipeBottomShade.style.width = 50.5 + "%";
      //底部横管道初始位置
      pipeBottom.style.left = -100 + "%";
      //底部纵管道2初始位置
      pipeDown2.style.top = 100 + "%";
      let i = 100; //动画演出长度
      this.aniP = setInterval(() => {
        if (i > 0) {
          pipeBottom.style.left = -i + "%";
          pipeDown2.style.top = 0 + "%";
          i = i - 3;
        } else {
          pipeBottom.style.left = 100 + "%";
          pipeDown2.style.top = 100 + "%";
          i = 100;
        }
      }, 100);
    },

    //K罐运行动画(施肥)
    aniForKFert() {
      this.closeAniForN();
      this.closeAniForP();
      this.closeAniForK();
      this.closeAniForW();
      if (this.aniPlay == true) {
        return;
      }
      this.aniPlay == true;
      var pipeBottomShade = this.$refs.pipeBottomShade;
      var pipeBottom = this.$refs.pipeBottom;
      var pipeDown3 = this.$refs.pipeDown3;
      var vd3 = this.$refs.vd3;
      var vm1 = this.$refs.vm1;
      var vm2 = this.$refs.vm2;
      var arrow = this.$refs.arrow;
      vd3.style.display = "block";
      vm1.style.display = "block";
      vm2.style.display = "block";
      arrow.style.display = "block";
      pipeBottomShade.style.width = 33.5 + "%";
      //底部横管道初始位置
      pipeBottom.style.left = -100 + "%";
      //底部纵管道3初始位置
      pipeDown3.style.top = 100 + "%";
      let i = 100; //动画演出长度
      this.aniK = setInterval(() => {
        if (i > 0) {
          pipeBottom.style.left = -i + "%";
          pipeDown3.style.top = 0 + "%";
          i = i - 3;
        } else {
          pipeBottom.style.left = 100 + "%";
          pipeDown3.style.top = 100 + "%";
          i = 100;
        }
      }, 100);
    },

    //清水罐运行动画
    aniForWFert() {
      this.closeAniForN();
      this.closeAniForP();
      this.closeAniForK();
      this.closeAniForW();
      if (this.aniPlay == true) {
        return;
      }
      this.aniPlay == true;
      var pipeBottomShade = this.$refs.pipeBottomShade;
      var pipeBottom = this.$refs.pipeBottom;
      var pipeDown4 = this.$refs.pipeDown4;
      var vd4 = this.$refs.vd4;
      var vm1 = this.$refs.vm1;
      var vm2 = this.$refs.vm2;
      var arrow = this.$refs.arrow;
      vd4.style.display = "block";
      vm1.style.display = "block";
      vm2.style.display = "block";
      arrow.style.display = "block";
      pipeBottomShade.style.width = 16.5 + "%";
      //底部横管道初始位置
      pipeBottom.style.left = -100 + "%";
      //底部纵管道4初始位置
      pipeDown4.style.top = 100 + "%";
      let i = 100; //动画演出长度
      this.aniW = setInterval(() => {
        if (i > 0) {
          pipeBottom.style.left = -i + "%";
          pipeDown4.style.top = 0 + "%";
          i = i - 3;
        } else {
          pipeBottom.style.left = 100 + "%";
          pipeDown4.style.top = 100 + "%";
          i = 100;
        }
      }, 100);
    },

    closeAniForN() {
      clearInterval(this.aniN);
      var pipeTopShade = this.$refs.pipeTopShade;
      var pipeTop = this.$refs.pipeTop;
      var pipeBottomShade = this.$refs.pipeBottomShade;
      var pipeBottom = this.$refs.pipeBottom;
      var pipeUp1 = this.$refs.pipeUp1;
      var pipeDown1 = this.$refs.pipeDown1;
      var pipeUp5 = this.$refs.pipeUp5;
      var vu1 = this.$refs.vu1;
      var vd1 = this.$refs.vd1;
      var vm1 = this.$refs.vm1;
      var vm2 = this.$refs.vm2;
      var arrow = this.$refs.arrow;
      pipeTopShade.style.width = 67 + "%";
      pipeBottomShade.style.width = 68 + "%";
      pipeTop.style.left = 100 + "%";
      pipeBottom.style.left = -100 + "%";
      pipeUp1.style.top = -100 + "%";
      pipeUp5.style.top = 100 + "%";
      pipeDown1.style.top = 100 + "%";
      vu1.style.display = "none";
      vd1.style.display = "none";
      vm1.style.display = "none";
      vm2.style.display = "none";
      arrow.style.display = "none";
      this.aniPlay = false;
    },
    closeAniForP() {
      clearInterval(this.aniP);
      var pipeTopShade = this.$refs.pipeTopShade;
      var pipeTop = this.$refs.pipeTop;
      var pipeBottomShade = this.$refs.pipeBottomShade;
      var pipeBottom = this.$refs.pipeBottom;
      var pipeUp2 = this.$refs.pipeUp2;
      var pipeUp5 = this.$refs.pipeUp5;
      var pipeDown2 = this.$refs.pipeDown2;
      var vu2 = this.$refs.vu2;
      var vd2 = this.$refs.vd2;
      var vm1 = this.$refs.vm1;
      var vm2 = this.$refs.vm2;
      var arrow = this.$refs.arrow;
      pipeTopShade.style.width = 49 + "%";
      pipeBottomShade.style.width = 50.5 + "%";
      pipeTop.style.left = 100 + "%";
      pipeBottom.style.left = -100 + "%";
      pipeUp2.style.top = -100 + "%";
      pipeUp5.style.top = 100 + "%";
      pipeDown2.style.top = 100 + "%";
      vu2.style.display = "none";
      vd2.style.display = "none";
      vm1.style.display = "none";
      vm2.style.display = "none";
      arrow.style.display = "none";
      this.aniPlay = false;
    },
    closeAniForK() {
      clearInterval(this.aniK);
      var pipeTopShade = this.$refs.pipeTopShade;
      var pipeTop = this.$refs.pipeTop;
      var pipeBottomShade = this.$refs.pipeBottomShade;
      var pipeBottom = this.$refs.pipeBottom;
      var pipeUp3 = this.$refs.pipeUp3;
      var pipeUp5 = this.$refs.pipeUp5;
      var pipeDown3 = this.$refs.pipeDown3;
      var vu3 = this.$refs.vu3;
      var vd3 = this.$refs.vd3;
      var vm1 = this.$refs.vm1;
      var vm2 = this.$refs.vm2;
      var arrow = this.$refs.arrow;
      pipeTopShade.style.width = 32 + "%";
      pipeBottomShade.style.width = 33.5 + "%";
      pipeTop.style.left = 100 + "%";
      pipeBottom.style.left = -100 + "%";
      pipeUp3.style.top = -100 + "%";
      pipeUp5.style.top = 100 + "%";
      pipeDown3.style.top = 100 + "%";
      vu3.style.display = "none";
      vd3.style.display = "none";
      vm1.style.display = "none";
      vm2.style.display = "none";
      arrow.style.display = "none";
      this.aniPlay = false;
    },
    closeAniForW() {
      clearInterval(this.aniW);
      var pipeBottomShade = this.$refs.pipeBottomShade;
      var pipeBottom = this.$refs.pipeBottom;
      var pipeUp5 = this.$refs.pipeUp5;
      //var vu4=this.$refs.vu4
      var vd4 = this.$refs.vd4;
      var vm1 = this.$refs.vm1;
      var vm2 = this.$refs.vm2;
      var arrow = this.$refs.arrow;
      pipeBottomShade.style.width = 16.5 + "%";
      pipeBottom.style.left = -100 + "%";
      //pipeUp3.style.top=-100+'%'
      pipeUp5.style.top = 100 + "%";
      //vu4.style.display='none'
      vd4.style.display = "none";
      vm1.style.display = "none";
      vm2.style.display = "none";
      arrow.style.display = "none";
      this.aniPlay = false;
    },
  },
};
</script>
  